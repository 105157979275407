<template>
  <div v-cloak :class="`${!isDesktop ? 'mobile' : '' } ${user && (user.group === 777 || user.group === 0) ? 'premium' : ''}`" :data-theme="theme" :style="`--color:#${themeColor}`">
    <MovieAds v-if="(!user || user.group === 1)" />
    <div v-if="isDesktop && (!user || user.group === 1)" v-show="showVideoAd" class="videoroll">
      <div @click="showVideoAd = !showVideoAd" style="text-align:right;">
        <i class="close" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-1740435-12"></div>
      </div>
    </div>
    
    <div
      v-if="!tabActive"
      class="on-blur"
      style="display:block;position:fixed;top:0px;left:0px;width:100%;height:100%;background-color:rgba(0,0,0,0.55);z-index:10;"
    />
    <nav v-if="isDesktop" class="menu">
      <div class="left">
        <!-- <nuxt-link to="/dobro" :class="{ 'active': $route.name === 'dobro' }">
          <img :src="`/static_files/icons/crown${$route.name === 'dobro' ? '-gold' :darkTheme ? '-dark' : ''}.svg`" alt="">
          Dobro
        </nuxt-link> -->
        <nuxt-link to="/catalog" :class="{ 'active': !$route.query.year && $route.name === 'catalog' }">
          <img :src="`/static_files/icons/paper${!$route.query.year && $route.name === 'catalog' ? '-blue' : darkTheme ? '-dark' : ''}.svg`" alt="">
          Каталог аниме
        </nuxt-link>
        <nuxt-link :to="`/catalog?year=${new Date().getFullYear()}`" :class="{ 'active': !!$route.query.year && $route.query.year === '' + new Date().getFullYear() }">
          <img :src="`/static_files/icons/new${!!$route.query.year && $route.query.year === '' + new Date().getFullYear() ? '-blue' : darkTheme ? '-dark' : ''}.svg`" alt="">
          Новинки {{ new Date().getFullYear() }}
        </nuxt-link>
        <nuxt-link to="/popular" :class="{ 'active': $route.name === 'popular' }">
          <img :src="`/static_files/icons/star${$route.name === 'popular' ? '-blue' : darkTheme ? '-dark' : ''}.svg`" alt="">
          Популярно
        </nuxt-link>
        <nuxt-link to="/chat" :class="{ 'active': $route.name === 'chat' }">
          <img :src="`/static_files/icons/chat${$route.name === 'chat' ? '-blue' : darkTheme ? '-dark' : ''}.svg`" alt="">
          Общий чат
        </nuxt-link>
      </div>
      <div class="center">
        <nuxt-link to="/" class="logo" :class="themeColor">ANIMEAST</nuxt-link>
      </div>
      <ThemeToggle />
      <div v-if="user" class="right">
        <div @click="showUserMenu = !showUserMenu" class="auth">
          <div class="avatar">
            <img :src="user.photo" :alt="user.name">
          </div>
          <strong>{{ user.name }}</strong>
        </div>
      </div>
      <div v-else class="right">
        <nuxt-link :to="`/signin?ref=${encodeURI($route.fullPath)}`" class="login">
          Войти
        </nuxt-link>
      </div>
    </nav>
    <nav v-else class="menu">
    <!-- <nav v-else-if="$route.name !== 'smotrim-id'" class="menu"> -->
      <nuxt-link to="/chat" tag="div" :class="{ 'active': $route.name === 'chat' }">
        <i class="icon-chat" />
        <strong>Общий чат</strong>
      </nuxt-link>
      <div @click="showLeftSide" :class="{ 'active': roomsList.length }">
        <i v-if="!roomsList.length" class="icon-rooms" />
        <i v-else class="icon-close" />
        <strong>Комнаты</strong>
      </div>
      <!-- <nuxt-link to="/dobro" tag="div" :class="{ 'active': $route.name === 'dobro' }">
        <i class="icon-crown" />
        <strong>Dobro</strong>
      </nuxt-link> -->
      <nuxt-link to="/" tag="div" :class="{ 'active': $route.name === 'index' }">
        <i class="icon-home" />
        <strong>Главная</strong>
      </nuxt-link>
      <nuxt-link :to="`/catalog?year=${new Date().getFullYear()}`" tag="div" :class="{ 'active': $route.name === 'catalog' }">
        <i class="icon-catalog" />
        <strong>Каталог</strong>
      </nuxt-link>
      <div v-if="user">
        <div @click="showUserMenu = !showUserMenu" class="auth">
          <div class="avatar">
            <img :src="user.photo" :alt="user.name">
          </div>
          <strong>Профиль</strong>
        </div>
        <input
          ref="avatarUpload"
          type="file"
          style="display:none;"
          accept="image/gif, image/png, image/jpeg, image/jpg"
          @change="uploadFile($event, 'avatar')"
        >
      </div>
      <div v-else>
        <nuxt-link :to="`/signin?ref=${encodeURI($route.fullPath)}`">
          <i class="icon-profile" />
          <strong>Войти</strong>
        </nuxt-link>
      </div>
    </nav>

    <div v-if="showUserMenu" class="user-menu">
      <!-- <div class="picker">
        <i @click="showPicker = !showPicker" />
        <div
          v-show="showPicker"
          class="picker-menu"
        >
          <div class="lang">
            <span @click="themeColor = 'e00a1e'" :style="`background-color:#e00a1e`">
              <b v-if="themeColor === 'e00a1e'">&#10003;</b>
            </span>
            <span @click="themeColor = 'faa200'" :style="`background-color:#faa200`">
              <b v-if="themeColor === 'faa200'">&#10003;</b>
            </span>
          </div>
          <div class="theme">
            <span @click="switchTheme('light')" class="light">
              <b v-if="theme === 'light'">&#10003;</b>
            </span>
            <span @click="switchTheme('dark')" class="dark">
              <b v-if="theme === 'dark'">&#10003;</b>
            </span>
          </div>
        </div>
      </div> -->

      <ThemeToggle v-if="!isDesktop" style="margin-bottom: 20px;" />

      <div>
        ID: {{ user.id }}
      </div>

      <!-- <div>
        Цветовая тема
        <div class="theme">
          <span @click="switchTheme('light')" class="light">
            <b v-if="theme === 'light'">&#10003;</b>
          </span>
          <span @click="switchTheme('dark')" class="dark">
            <b v-if="theme === 'dark'">&#10003;</b>
          </span>
        </div>
      </div> -->

      <div style="margin: 20px 0px;" />
      
      <div>
        <strong>{{ user.name }}</strong>
        <i @click="showEditName = !showEditName" class="edit" />
      </div>
      <div v-if="showEditName">
        <input v-model="newName" type="text" min="3" max="30" placeholder="Новое имя">
        <div>
          <button @click="changeName" class="nickname">
            Изменить
          </button>
          <button @click="showEditName = !showEditName" class="nickname">
            Отменить
          </button>
        </div>
        <p style="font-size:1.2rem;">Первая смена бесплатно, 2-я и последующие 10руб.</p>
      </div>
      <input
        ref="avatarUpload"
        type="file"
        style="display:none;"
        accept="image/gif, image/png, image/jpeg, image/jpg"
        @change="uploadFile($event, 'avatar')"
      >
      <div @click="avatarUploadTrigger" class="avatar">
        <img :src="user.photo" alt="">
        <span>Изменить</span>
      </div>
      <div class="row" style="text-align:right;font-size:1.2rem;text-transform:uppercase;">
        <nuxt-link to="/dobro" tag="span">
            <b
              style="margin-right:5px;padding:0px 6px;color:#000;border-radius:20px;background-color:#fad200;"
            >Premium</b>
            <template v-if="user.group === 777">до {{ user.premium_date | filterDate }}</template>
            <template v-else>Подключить</template>
        </nuxt-link>
      </div>
      <!-- <div style="text-align:right;font-size:1.3rem;">
        <nuxt-link to="/dobro" tag="span" v-if="user.group === 777">
            <b
              style="padding:0px 6px;color:#000;border-radius:20px;background-color:#fad200;"
            >Premium</b> до {{ user.premium_date | filterDate }}
        </nuxt-link>
        <nuxt-link to="/dobro" tag="span">
          <b>Баланс: {{ user.balance }}
            <img src="/static_files/icons/chevron-right.svg" alt="" style="width:12px;height:12px;">
          </b>
        </nuxt-link>
      </div> -->
      <div style="text-align:right;">
        <nuxt-link to="/dobro" tag="span">Баланс: <b style="vertical-align:middle;">{{ user.balance }} +</b></nuxt-link>
      </div>
      <div>
        <nuxt-link to="/profile/history" tag="span">История просмотров</nuxt-link>
      </div>
      <div>
        <nuxt-link to="/profile/favorites" tag="span">Избранное</nuxt-link>
      </div>
      <div>
        <nuxt-link to="/dobro" tag="span">Подписка</nuxt-link>
      </div>
      <div>
        <span @click="logout">Выйти</span>
      </div>

      <div v-if="!isDesktop" style="margin:40px 10px 0px;text-align:center;">
        <a href="https://animeast.su/static_files/app/animeast_android_v1.apk" target="_blank">
          <img src="/static_files/icons/google.svg" alt="Animeast на Google Play">
        </a>
      </div>

      <div v-if="!isDesktop" class="links">
        <a href="mailto:@support@animeast.su" target="_blank">
          support@animeast.su
        </a>
        <a href="https://vk.com/animeast" target="_blank">
          ВКонтакте
        </a>
        <a href="https://t.me/animeastru" target="_blank">
          Телеграм
        </a>
      </div>

      <div>
        <i @click="showUserMenu = !showUserMenu" class="close" />
      </div>
    </div>

    <div class="main-wrapper" :class="{'inroom': inRoom}">
      <div v-if="(isDesktop || roomsList.length)" class="left-side">
        <div v-show="isDesktop" id="yandex_rtb_R-A-1740435-14" class="rsya-inf"></div>
        <div class="rooms-add">
          <!-- <strong>Создать комнату</strong> -->
          <SH :type="`rooms`" />
        </div>
        <div class="rooms-list">
          <strong>Активные комнаты</strong>
          <div>
            <div style="display:grid;grid-template-columns:repeat(2,1fr);">
              <span style="display:inline-block;font-size:1.2rem;">
                Всего {{ roomsCount }}
                <!-- <template v-if="(roomsCount - roomsList.length)">
                  | Приватные {{ roomsCount - roomsList.length }}
                </template> -->
              </span>
              <span v-if="user" @click="(myRooms = !myRooms)" style="text-align:right;color:#1da1f2;cursor:pointer;">
                {{ !myRooms ? 'Мои комнаты' : 'Все комнаты' }}
              </span>
            </div>

            <div v-if="roomsList.length" class="items">
              <ul style="height:100%;">
                <nuxt-link
                  v-for="item in roomsList"
                  :key="`room-${item._id}`"
                  :to="!item.hidden || (item.owner && user && user.id === item.owner.id) ? `/smotrim/${item._id}` : ''"
                  tag="li"
                  :class="{
                    'private': item.hidden,
                    'active': $route.params.id === item._id,
                    'vmeste': item.vmeste,
                    'common': !item.owner,
                    'self': item.owner && user && user.id === item.owner.id
                  }"
                >
                  <div class="poster">
                    <img :data-src="filmPoster(item.film)" src="/static_files/no-poster.png" :alt="item.film.title_ru" class="lazyload" />
                  </div>
                  <div class="title">
                    <strong><i v-if="item.hidden" class="icon-private" />{{ item.film.title_ru }}</strong>
                    <span class="en">{{ item.film.title_en }}</span>
                    <span>
                      <nuxt-link :to="`/smotrim?id=${item.id}`">Копировать</nuxt-link>
                      <u v-if="item.vmeste">Сеанс в 21:00</u>
                      <u v-else-if="item.hidden">Приватная</u>
                      <u v-else-if="user && item.owner && item.owner.id === user.id">Ваша комната</u>
                      <u v-else>{{ item.owner ? item.owner.name : 'Общая комната' }}</u>
                      <template v-if="item.viewers"><b></b>Участники: {{ item.viewers }}</template>
                    </span>
                  </div>
                </nuxt-link>
                <li v-if="(roomsList.length >= 20 && roomsList.length < roomsCount)">
                  <div></div>
                  <div @click="getRooms(++roomsPage)" class="show-more">
                    <button>
                      <span>Показать еще</span>
                      <i class="arrow" />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="content-scroll">
        <template v-if="(!user || user.group === 1)">
          <div v-show="!inRoom" id="yandex_rtb_R-A-1740435-5" class="rsya-block"></div>
          <nuxt-link v-if="!inRoom && (!user || (user.group !== 777 && user.group !== 0))" to="/dobro" tag="div" class="close-ad">
            Убрать рекламу
          </nuxt-link>
        </template>

        <TopFilms v-if="$route.name !== 'smotrim-id'" />

        <nuxt />

        <footer v-if="$route.name === 'index'">
          <div class="links">
            <a href="mailto:@support@animeast.su" target="_blank">
              support@animeast.su
            </a>
            <a href="https://vk.com/animeast" target="_blank">
              ВКонтакте
            </a>
            <a href="https://t.me/animeastru" target="_blank">
              Телеграм
            </a>
          </div>
        </footer>
      </div>
    </div>

    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
// import LazyHydrate from 'vue-lazy-hydration'
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    ThemeToggle: () => import('~/components/ThemeToggle.vue'),
    MovieAds: () => import('~/components/MovieAds.vue'),
    Error: () => import('~/components/Error.vue'),
    SH: () => import('~/components/SH.vue'),
    TopFilms: () => import('~/components/TopFilms.vue'),
    // Menu: () => import('~/components/Menu.vue')
  },
  filters: {
    filterDate(value) {
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth() + 1
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day}.${month}.${year} ${hour}:${min}`
    }
  },
  watch: {
    myRooms(val) {
      this.getRooms()
      if (!this.isDesktop) {
        if (val) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      }
    },
    changeRoute() {
      document.body.style.overflow = ''
      // const contentScroll = document.querySelector('.content-scroll')
      // if (contentScroll) contentScroll.scroll(0, 0)

      this.showUserMenu = false
      if (!this.isDesktop) this.roomsList = []
    
      // document.querySelector('.content-scroll').removeEventListener('scroll', this.lazyLoadImgs)

      // if (!this.isDesktop && (!this.user || (this.user.group !== 777 && this.user.group !== 0))) {
      //   this.appendFS()
      // }

      // this.$nextTick(() => {
      //   const cScroll = document.querySelector('.content-scroll')
      //   if (cScroll) {
      //     cScroll.style.overflow = ''
      //     cScroll.addEventListener('scroll', this.lazyLoadImgs)
      //     cScroll.scroll(0, 0)
      //   }
      // })
    },
    tabActive(val) {
      if (!val) {
        clearInterval(this.getRoomsInverval)
      } else if (this.isDesktop) {
        this.getRooms()
        this.getRoomsInverval = setInterval(() => this.getRooms(), 30 * 1000)
      }
    },
    // roomsI() {
    //   this.$nextTick(() => {
    //     this.lazyLoadImgs()
    //   })
    // }
  },
  computed: {
    ...mapState(['error', 'isDesktop', 'user', 'tabActive', 'playerPlay', 'theme', 'darkTheme', 'popular']),
    inRoom() {
      return this.$route.name === 'smotrim-id'
    },
    changeRoute() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      showFS: false,
      themeColor: 'e00a1e',
      showPicker: false,
      lastupdate: Date.now(),
      roomsList: [],
      roomsCount: 0,
      myRooms: null,
      roomsPage: 0,
      showUserMenu: false,
      showEditName: false,
      newName: '',
      onBlurTimeout: null,
      getRoomsInverval: null,
      showVideoAd: true
    }
  },
  mounted() {
    // setInterval(() => {
    //   this.appendFS()
    // }, 15 * 1000)

    if (!this.$device.isDesktop) {
      const mobileElem = document.querySelector('.mobile')
      const menuElem = document.querySelector('.mobile .menu')

      setInterval(() => {
        let findBlock = 0
        document.body.querySelectorAll('div').forEach(el => {
          if (el.style.zIndex === '2147483647') {
            // console.log(el.children)

            findBlock = 1

            if (menuElem && mobileElem) {
              if (el.children[1] && el.children[1].style.opacity === '1') {
                // console.log(el.children[1].style.opacity)

                menuElem.style.bottom = 31 + 'vh'
                mobileElem.style.paddingBottom = 31 + 'vh'
                
              } else {
                menuElem.style.bottom = 0 + 'px'
                mobileElem.style.paddingBottom = 0 + 'px'
              }
            }
          }
        })

        if (!findBlock) {
          if (menuElem && mobileElem) {
            menuElem.style.bottom = 0 + 'px'
            mobileElem.style.paddingBottom = 0 + 'px'
          }
        }
      }, 1000)
    }
    

    this.showAds()

    // window.addEventListener('scroll', this.lazyLoadImgs)
    // this.lazyLoadImgs()
    
    // window.addEventListener('scroll', this.lazyLoadImgs)

    if (this.isDesktop) {
      this.getRooms()
      this.getRoomsInverval = setInterval(() => this.getRooms(), 30 * 1000)
    }

    // window.addEventListener('blur', this.onBlur)
    // window.addEventListener('focus', this.onFocus)

    const vm = this

    let hidden = "hidden";

    // Standards:
    if (hidden in document) {
      document.addEventListener("visibilitychange", onchange);
    } else if ((hidden = "mozHidden") in document) {
      document.addEventListener("mozvisibilitychange", onchange);
    } else if ((hidden = "webkitHidden") in document) {
      document.addEventListener("webkitvisibilitychange", onchange);
    } else if ((hidden = "msHidden") in document) {
      document.addEventListener("msvisibilitychange", onchange);
    } else if ("onfocusin" in document) {
      // IE 9 and lower:
      document.onfocusin = document.onfocusout = onchange;
    } else {
      // All others:
      window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
    }

    function onchange (evt) {
      const v = "visible"
      const h = "hidden"
      const evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h
      };

      evt = evt || window.event;

      if (evt.type in evtMap) {
        document.body.className = evtMap[evt.type];
        if (vm.isDesktop) {
          // if (!vm.playerPlay)
          vm.$store.commit('setTabActive', evtMap[evt.type] === 'hidden' ? 0 : 1)
        } else {
          vm.$store.commit('setTabActive', evtMap[evt.type] === 'hidden' ? 0 : 1)
        }
      } else {
        document.body.className = this[hidden] ? "hidden" : "visible";
        if (vm.isDesktop) {
          // if (!vm.playerPlay)
          vm.$store.commit('setTabActive', this[hidden] ? 0 : 1)
        } else {
          vm.$store.commit('setTabActive', this[hidden] ? 0 : 1)
        }
      }
      // console.log(vm.tabActive)
    }

    // set the initial state (but only if browser supports the Page Visibility API)
    if (document[hidden] !== undefined) {
      onchange({ type: document[hidden] ? "blur" : "focus" });
    }

    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px')
    window.addEventListener('resize', () => { 
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px')
    })

    const currentTheme = localStorage.getItem('theme')
    if (currentTheme === 'dark') {
      document.body.classList.add('dark')
      this.$store.commit('setDarkTheme', true)
    } else {
      document.body.classList.remove('dark')
      this.$store.commit('setDarkTheme', false)
    }
  },
  methods: {
//     appendFS() {
//       if (this.showFS) return
//       const rsyaMobileFS = document.createElement('script')
//       rsyaMobileFS.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.render({
//     type: 'fullscreen',
//     blockId: 'R-A-1740435-4'
//   })
// })`
//       document.body.appendChild(rsyaMobileFS)
//       this.showFS = true
//     },
    showAds() {
      if (!this.user || this.user.group === 1) {
        setInterval(() => {
          this.showVideoAd = true
        }, 5 * 60 * 1000)

        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-1740435-5',
      blockId: 'R-A-1740435-5'
    })
  })`
        document.body.appendChild(rsyaHorizon)

        if (!this.isDesktop) {
          const rsyaMobileFS = document.createElement('script')
          rsyaMobileFS.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'fullscreen',
      blockId: 'R-A-1740435-4'
    })
  })`
          document.body.appendChild(rsyaMobileFS)

          const rsyaFooterMobile = document.createElement('script')
          rsyaFooterMobile.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'floorAd',
      blockId: 'R-A-1740435-10'
    })
  })`
          document.body.appendChild(rsyaFooterMobile)
        } else {
          const rsyaDesktopVideoAd = document.createElement('script')
          rsyaDesktopVideoAd.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-1740435-12',
      blockId: 'R-A-1740435-12'
    })
  })
    `
          document.body.appendChild(rsyaDesktopVideoAd)

          const rsyaLeft = document.createElement('script')
          rsyaLeft.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-1740435-14',
      blockId: 'R-A-1740435-14'
    })
  })`
        document.body.appendChild(rsyaLeft)
        }
        }, 200)
      }
    },
    switchColor(lang) {
      document.body.dataset.lang = lang
    }, 
    // async switchTheme(theme) {
    //   if (theme === this.theme) return
    //   const { data } = await this.$axios.post(`/api/user/theme`, { theme })
    //   // document.body.dataset.theme = data.theme

    //   if (data.error) {
    //     this.$store.commit('setError', { status: 'info', msg: data.error.message })
    //     setTimeout(() => this.$store.commit('clearError'), 2000)
    //   } else {
    //     this.$store.commit('setTheme', data.theme)
    //   }
    // }, 
    onBlur() {
      console.log('onBlur')
      
      this.onBlurTimeout = setTimeout(() => {
        if (!this.playerPlay) this.$store.commit('setTabActive', 0)
      }, 20 * 1000)
    },
    onFocus() {
      console.log('onFocus')

      clearTimeout(this.onBlurTimeout)

      this.$store.commit('setTabActive', 1)
    },
    async changeName() {
      if (!this.user) return
      const badName = ['002', '002.z', 'bot', 'admin', 'administrator', 'animeast', 'moderator', 'админ', 'бот', 'анимист', 'администратор']
      if (
        !this.newName.trim() ||
        badName.includes(this.newName.toLowerCase()) ||
        this.newName.match(/[^a-zA-Zа-яА-Я0-9 _].+/)
      ) {
        this.$store.commit('setError', { status: 'warn', msg: 'Неверный формат никнейма' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length < 3) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком короткое имя (мин. 3)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length > 30) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком длинное имя (макс. 30)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const { data } = await this.$axios.post('/api/user/nickname', { name: this.newName.trim() })
        if (data.error) {
          this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        } else {
          const user = this.user
          user.name = data.name
          this.$store.commit('setUser', user)
          this.newName = ''
          this.showEditName = false
          this.$store.commit('setError', { status: 'ok', msg: 'Никнейм успешно изменен' })
        }
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    avatarUploadTrigger() {
      this.$refs.avatarUpload.click()
    },
    uploadFile(event, typeImg) {
      const file = event.target.files[0]

      if (file) {
        const fileType = file.type
        const imageExt = file.name.slice(file.name.lastIndexOf('.'))
        const imageSize = file.size / 1024

        if (
          (imageExt === '.gif' && imageSize < 1000) ||
          (imageExt === '.jpeg' && imageSize < 5000) ||
          (imageExt === '.jpg' && imageSize < 5000) ||
          (imageExt === '.png' && imageSize < 5000)
        ) {
          const vm = this

          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = function() {
            const image = new Image()
            image.src = reader.result

            image.onload = async function() {
              let maxWidth, maxHeight, finalFile

              if (typeImg === 'avatar') {
                maxWidth = 120
                maxHeight = 120
              }

              if (typeImg === 'avatar' && imageExt === '.gif') {
                finalFile = reader.result
              } else {
                let imageWidth = image.width
                let imageHeight = image.height

                if ((imageWidth > imageHeight) && (imageWidth > maxWidth)) {
                  imageHeight *= maxWidth / imageWidth
                  imageWidth = maxWidth
                } else if (imageHeight > maxHeight) {
                  imageWidth *= maxHeight / imageHeight
                  imageHeight = maxHeight
                }

                const canvas = document.createElement('canvas')
                canvas.width = imageWidth
                canvas.height = imageHeight

                const ctx = canvas.getContext('2d')
                ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

                finalFile = canvas.toDataURL(fileType)
              }

              // The resized file ready for upload
              const fileName = `${vm.user.id}${imageExt}`

              const arr = finalFile.split(',')
              const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) u8arr[n] = bstr.charCodeAt(n)

              const imageFile = new File([u8arr], fileName, { type: mime })

              vm.$store.commit('setError', { status: 'load', msg: 'Идет загрузка' })

              const bodyFormData = new FormData()
              bodyFormData.append('image', imageFile)

              const { data } = await vm.$axios({
                method: 'post',
                url: `/upload?type=${typeImg}s`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              vm.$store.commit('setError', { status: 'ok', msg: 'Аватар установлен' })
              setTimeout(() => vm.$store.commit('clearError'), 1000)
              if (typeImg === 'avatar') {
                await vm.$axios.put('/api/user', { photo: data.url })
                const user = vm.user
                user.photo = data.url
                vm.$store.commit('setUser', user)
              }
            }
          }
        } else {
          this.$store.commit(
            'setError',
            'Файл не соответствует условиям: формат .gif, .jpg, .jpeg или .png размером до 5Mb'
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      } else {
        this.$store.commit('setError', 'Ошибка загрузки')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    showLeftSide() {
      if (this.roomsList.length) {
        this.roomsList = []
      } else {
        this.getRooms()
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      location.reload()
    },
    roomsMore() {
      // this.lazyLoadImgs()

      const roomsMoreElem = document.querySelector('.rooms-list .items .rooms-more')
    
      if (roomsMoreElem) {
        const docHeight = document.documentElement.clientHeight
        const topPosition = docHeight - roomsMoreElem.getBoundingClientRect().top
        if (+topPosition) {
          ++this.roomsI
        }
      }
    },
    getRooms(page = 0) {
      const body = {
        page,
        user_id: this.myRooms ? this.user.id : null,
      }
      this.$socket.emit(`animeast:rooms:get`, body, (cb) => {
        this.roomsCount = cb.count
        const rooms = this.roomsPage ? this.roomsList.concat(cb.list) : cb.list
        this.roomsList = rooms

        this.$nextTick(() => {
          // this.lazyLoadImgs()
          const roomsListElem = document.querySelector('.rooms-list .items')
          if (roomsListElem) {
            roomsListElem.removeEventListener('scroll', this.roomsMore)
            roomsListElem.addEventListener('scroll', this.roomsMore)
          }
        })
      })
    }
  }
}
</script>

<style>
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus, textarea:focus, input:focus {
    font-size: 16px;
  }
}
</style>
