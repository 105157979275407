export const strict = false

export const state = () => ({
  user: null,
  error: '',
  chatMembers: [],
  tabActive: 1,
  playerPlay: false,
  playerAction: null,
  darkTheme: false,
  theme: 'light',
  popular: [],
})

export const mutations = {
  setPopular(state, payload) {
    state.popular = payload
  },
  setDarkTheme(state, payload) {
    state.darkTheme = payload
  },
  setTheme(state, payload) {
    state.theme = payload
  },
  setPlayerPlay(state, payload) {
    state.playerPlay = payload
  },
  setTabActive(state, payload) {
    state.tabActive = payload
  },
  setPlayerAction(state, payload) {
    state.playerAction = payload
    setTimeout(() => {
      state.playerAction = null
    }, 1000)
  },
  setChatUsers(state, payload) {
    state.chatMembers = payload
  },
  setDevice(state, payload) {
    state.isDesktop = payload.isDesktop
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  clearError(state) {
    state.error = ''
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) {
        commit('setUser', req.user)
        commit('setTheme', req.user.theme)
        // commit('setPopular', req.user.popular)
      }
      commit('setDevice', app.$device)
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}
