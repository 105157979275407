import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _63a31482 = () => interopDefault(import('../pages/adblock.vue' /* webpackChunkName: "pages/adblock" */))
const _bd12f6d8 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _b0ae4560 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _49629a63 = () => interopDefault(import('../pages/common.vue' /* webpackChunkName: "pages/common" */))
const _ff65f902 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _7a2662a8 = () => interopDefault(import('../pages/cp/index.vue' /* webpackChunkName: "pages/cp/index" */))
const _49e0e86f = () => interopDefault(import('../pages/dobro/index.vue' /* webpackChunkName: "pages/dobro/index" */))
const _c9117190 = () => interopDefault(import('../pages/dobro1/index.vue' /* webpackChunkName: "pages/dobro1/index" */))
const _f5849d5c = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _85bf5cde = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _9c32da80 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _506afa24 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _6c7b9a22 = () => interopDefault(import('../pages/promocodes.vue' /* webpackChunkName: "pages/promocodes" */))
const _607da6cc = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _275c25f2 = () => interopDefault(import('../pages/smotrim/index.vue' /* webpackChunkName: "pages/smotrim/index" */))
const _0cb1f9bb = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _037fd8a1 = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _6cfd04ee = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _5b1a99f2 = () => interopDefault(import('../pages/cp/chat.vue' /* webpackChunkName: "pages/cp/chat" */))
const _4ebd34e4 = () => interopDefault(import('../pages/cp/comments.vue' /* webpackChunkName: "pages/cp/comments" */))
const _4210c0ab = () => interopDefault(import('../pages/cp/desc.vue' /* webpackChunkName: "pages/cp/desc" */))
const _5e7e15ee = () => interopDefault(import('../pages/cp/donate.vue' /* webpackChunkName: "pages/cp/donate" */))
const _414c429f = () => interopDefault(import('../pages/cp/feedback.vue' /* webpackChunkName: "pages/cp/feedback" */))
const _361a728e = () => interopDefault(import('../pages/cp/kino.vue' /* webpackChunkName: "pages/cp/kino" */))
const _80336a88 = () => interopDefault(import('../pages/cp/streamers.vue' /* webpackChunkName: "pages/cp/streamers" */))
const _fe64cf34 = () => interopDefault(import('../pages/cp/unsubs.vue' /* webpackChunkName: "pages/cp/unsubs" */))
const _32b0bc20 = () => interopDefault(import('../pages/cp/vmeste.vue' /* webpackChunkName: "pages/cp/vmeste" */))
const _3e0a6269 = () => interopDefault(import('../pages/dobro1/success.vue' /* webpackChunkName: "pages/dobro1/success" */))
const _d26af7ae = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _351e0334 = () => interopDefault(import('../pages/profile/history.vue' /* webpackChunkName: "pages/profile/history" */))
const _8cd99502 = () => interopDefault(import('../pages/catalog/_id/index.vue' /* webpackChunkName: "pages/catalog/_id/index" */))
const _58cbe03a = () => interopDefault(import('../pages/favorites/_id.vue' /* webpackChunkName: "pages/favorites/_id" */))
const _b2261046 = () => interopDefault(import('../pages/smotrim/_id/index.vue' /* webpackChunkName: "pages/smotrim/_id/index" */))
const _7e452e09 = () => interopDefault(import('../pages/catalog/_id/_section.vue' /* webpackChunkName: "pages/catalog/_id/_section" */))
const _813ee9ec = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adblock",
    component: _63a31482,
    name: "adblock"
  }, {
    path: "/catalog",
    component: _bd12f6d8,
    name: "catalog"
  }, {
    path: "/chat",
    component: _b0ae4560,
    name: "chat"
  }, {
    path: "/common",
    component: _49629a63,
    name: "common"
  }, {
    path: "/copyright",
    component: _ff65f902,
    name: "copyright"
  }, {
    path: "/cp",
    component: _7a2662a8,
    name: "cp"
  }, {
    path: "/dobro",
    component: _49e0e86f,
    name: "dobro"
  }, {
    path: "/dobro1",
    component: _c9117190,
    name: "dobro1"
  }, {
    path: "/favorites",
    component: _f5849d5c,
    name: "favorites"
  }, {
    path: "/popular",
    component: _85bf5cde,
    name: "popular"
  }, {
    path: "/privacy",
    component: _9c32da80,
    name: "privacy"
  }, {
    path: "/profile",
    component: _506afa24,
    name: "profile"
  }, {
    path: "/promocodes",
    component: _6c7b9a22,
    name: "promocodes"
  }, {
    path: "/signin",
    component: _607da6cc,
    name: "signin"
  }, {
    path: "/smotrim",
    component: _275c25f2,
    name: "smotrim"
  }, {
    path: "/success",
    component: _0cb1f9bb,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _037fd8a1,
    name: "termsofuse"
  }, {
    path: "/unsubscribe",
    component: _6cfd04ee,
    name: "unsubscribe"
  }, {
    path: "/cp/chat",
    component: _5b1a99f2,
    name: "cp-chat"
  }, {
    path: "/cp/comments",
    component: _4ebd34e4,
    name: "cp-comments"
  }, {
    path: "/cp/desc",
    component: _4210c0ab,
    name: "cp-desc"
  }, {
    path: "/cp/donate",
    component: _5e7e15ee,
    name: "cp-donate"
  }, {
    path: "/cp/feedback",
    component: _414c429f,
    name: "cp-feedback"
  }, {
    path: "/cp/kino",
    component: _361a728e,
    name: "cp-kino"
  }, {
    path: "/cp/streamers",
    component: _80336a88,
    name: "cp-streamers"
  }, {
    path: "/cp/unsubs",
    component: _fe64cf34,
    name: "cp-unsubs"
  }, {
    path: "/cp/vmeste",
    component: _32b0bc20,
    name: "cp-vmeste"
  }, {
    path: "/dobro1/success",
    component: _3e0a6269,
    name: "dobro1-success"
  }, {
    path: "/profile/favorites",
    component: _d26af7ae,
    name: "profile-favorites"
  }, {
    path: "/profile/history",
    component: _351e0334,
    name: "profile-history"
  }, {
    path: "/catalog/:id",
    component: _8cd99502,
    name: "catalog-id"
  }, {
    path: "/favorites/:id",
    component: _58cbe03a,
    name: "favorites-id"
  }, {
    path: "/smotrim/:id",
    component: _b2261046,
    name: "smotrim-id"
  }, {
    path: "/catalog/:id/:section",
    component: _7e452e09,
    name: "catalog-id-section"
  }, {
    path: "/",
    component: _813ee9ec,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
